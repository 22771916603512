export enum StripePlanEnum {
	free = 'free',
	premium = 'premium',
	'premium-custom' = 'premium-custom',
}

export const StripePlansLabels: Record<StripePlanEnum, string> = {
	[StripePlanEnum.free]: 'Formule Gratuite',
	[StripePlanEnum.premium]: 'Formule Premium',
	[StripePlanEnum['premium-custom']]: 'Formule Sur Mesure',
};

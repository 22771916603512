'use client';

import ChevronRightIcon from '@/components/icons/chevron-right';
import type { Faq } from '@/models/faq';
import { clsx } from 'clsx';
import { memo, useId, useState } from 'react';

function FaqListItem({ faq }: { faq: Faq }) {
	const [expanded, setExpanded] = useState(false);
	const id = useId();

	return (
		<>
			<dt>
				<button
					type="button"
					aria-expanded={expanded}
					aria-controls={id}
					onClick={() => {
						setExpanded(!expanded);
					}}
					className={clsx(
						'flex w-full items-center justify-between border-l border-r border-t border-grey-light p-6 text-left text-base font-medium leading-6',
						{
							'rounded-2xl border-b': !expanded,
							'rounded-t-2xl': expanded,
						},
					)}
				>
					<span
						dangerouslySetInnerHTML={{ __html: faq.attributes.question }}
						className="prose font-semibold"
					></span>
					<ChevronRightIcon
						width={24}
						height={24}
						className={clsx('transition-all', {
							'rotate-90': !expanded,
							'rotate-[270deg]': expanded,
						})}
					/>
				</button>
			</dt>
			<dd>
				<div
					id={id}
					dangerouslySetInnerHTML={{ __html: faq.attributes.answer }}
					className={clsx(
						'prose -mt-3.5 mb-[2.125rem] w-full max-w-full overflow-hidden px-6 text-base leading-6 text-grey-darker transition-all ease-in-out marker:text-grey-darker',
						{
							'max-h-0 opacity-0': !expanded,
							'opacity-1 rounded-b-2xl border-b border-l border-r border-grey-light pb-6': expanded,
						},
					)}
				></div>
			</dd>
		</>
	);
}

export default memo(FaqListItem);

'use client';

import Emoji from '@/components/commons/emoji';
import LinkButton from '@/components/commons/link-button';
import ArrowCircleRightIcon from '@/components/icons/arrow-circle-right';
import XIcon from '@/components/icons/x';
import { menuItems } from '@/contents/home-menu';
import Logo from '@/public/logos/logo.svg';
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';
import { clsx } from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import { memo, useEffect, useState } from 'react';

function NavDesktop({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}) {
	const mdSize = 768;

	const { width: windowWidth } = useWindowSize();
	const [tabIndex, setTabIndex] = useState<number | undefined>(undefined);
	const [currentHash, setCurrentHash] = useState<string | undefined>(undefined);
	const params = useParams();
	const pathname = usePathname();
	const [{ y }] = useWindowScroll();

	useEffect(() => {
		updateCurrentItemWithHash(window.location.hash);
	}, []);

	useEffect(() => {
		updateCurrentItemWithHash(window.location.hash);
	}, [params]);

	useEffect(() => {
		if (windowWidth && windowWidth < mdSize) {
			setTabIndex(-1);
		} else {
			setTabIndex(undefined);
		}
	}, [windowWidth]);

	const updateCurrentItemWithHash = (hash: string) => {
		setCurrentHash(hash === '' ? undefined : hash);
	};

	return (
		<nav
			className={clsx(
				'bg-white transition-all lg:min-h-[6.375rem] lg:max-w-[100vw] lg:px-20 lg:py-[1.5625rem] lg:backdrop-blur-[20px]',
				{
					'fixed bottom-0 left-0 right-0 top-0 z-[60]': isOpen,
					'h-0 w-0 translate-x-full overflow-hidden lg:sticky lg:top-0 lg:z-30 lg:h-auto lg:w-auto lg:translate-x-0':
						!isOpen,
					'lg:shadow-[20px_4px_25px_0_rgba(0,0,0,0.05)]': (y ?? 0) > 0,
					'lg:shadow-[20px_4px_25px_0_rgba(0,0,0,0)]': (y ?? 0) === 0,
				},
			)}
		>
			<ul
				className={clsx('flex', {
					'flex-row items-center justify-between': !isOpen,
					'h-full flex-col gap-[2.1875rem] lg:gap-5': isOpen,
				})}
			>
				<li
					className={clsx('flex items-center justify-end px-5 lg:hidden', {
						'min-h-[5rem] bg-white shadow-[0_0_20px_0_rgba(115,108,165,0.10)]': isOpen,
					})}
				>
					<ul className="grid w-full grid-cols-2 items-center">
						<li className="justify-self-start">
							<Link href="/" aria-label="Lien pour retourner à la page d'accueil">
								<Image src={Logo} alt="" role="presentation" width={123} priority />
							</Link>
						</li>

						<li className="justify-self-end">
							<button
								className="flex h-10 w-10 items-center justify-center rounded-[14px] border-[0.0625rem] border-grey-light"
								type="button"
								onClick={() => {
									setIsOpen(false);
								}}
								tabIndex={tabIndex}
								aria-label="Fermer le menu de navigation"
							>
								<XIcon width={24} height={24} />
							</button>
						</li>
					</ul>
				</li>
				<li>
					<ul
						className={clsx({
							'grid grid-cols-[repeat(4,minmax(0,max-content))] items-center gap-10': !isOpen,
							'flex flex-col gap-[1.125rem] px-5': isOpen,
						})}
					>
						<li className="hidden lg:mr-[3.0625rem] lg:block">
							<Link
								tabIndex={tabIndex}
								href="/"
								className="title-font mb-4 flex items-center font-medium text-gray-900 lg:mb-0"
								aria-label="Lien pour retourner à la page d'accueil"
							>
								<Image
									src={Logo}
									alt=""
									role="presentation"
									title="Logo de la plateforme Lokappy"
									width={123}
									priority
								/>
							</Link>
						</li>

						{menuItems.map((item) => (
							<li key={item.id}>
								<Link
									href={item.link}
									className={clsx({
										'text-base font-normal leading-6 hover:underline hover:underline-offset-2':
											!isOpen,
										'underline underline-offset-2':
											(currentHash === item.link || pathname === item.link) && !isOpen,
										'flex items-center gap-3 rounded-xl px-[0.8125rem] py-[0.625rem]': isOpen,
										'bg-dodger-blue-500 bg-opacity-10':
											(currentHash === item.link || pathname === item.link) && isOpen,
									})}
									onClick={() => {
										setIsOpen(false);
									}}
									aria-current={
										currentHash === item.link || pathname === item.link ? 'page' : undefined
									}
								>
									<Emoji name={item.emoji} width={32} height={32} className="lg:hidden" />
									{item.text}
								</Link>
							</li>
						))}
					</ul>
				</li>

				<li className="mb-[2.6875rem] mt-auto lg:mb-0 lg:mt-0">
					<ul
						className={clsx({
							'grid grid-cols-[repeat(2,minmax(0,max-content))] items-center gap-5': !isOpen,
							'flex flex-col-reverse items-center gap-5': isOpen,
						})}
					>
						<li>
							<Link
								href="/sign-in"
								className="text-base font-medium leading-6 hover:underline hover:underline-offset-2"
							>
								Se connecter
							</Link>
						</li>
						<li className="w-full px-5 lg:w-auto lg:px-0">
							<LinkButton href="/sign-up" className="hidden lg:flex">
								Gérer mes biens
								<ArrowCircleRightIcon width={24} height={24} />
							</LinkButton>
							<LinkButton href="/sign-up" className="flex lg:hidden">
								Découvrir&nbsp;-&nbsp;C&apos;est gratuit
							</LinkButton>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
	);
}

export default memo(NavDesktop);

'use client';

import { cn } from '@/lib/utils';
import { dispatchAriaLiveContent } from '@/utils/aria-live-event';
import { removeHash } from '@/utils/location';
import { useParams } from 'next/navigation';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

/* eslint-disable @typescript-eslint/no-unnecessary-condition */

export default function Carousel({
	items,
	carouselClass,
	itemClass,
	setCurrentSlide: setCurrentSlideProps,
	useSwipe = true,
	defaultIndex,
}: {
	items: {
		title?: React.ReactNode;
		content: React.ReactNode;
		href?: string;
	}[];
	carouselClass?: string;
	itemClass?: string;
	setCurrentSlide?: Dispatch<SetStateAction<number>>;
	useSwipe?: boolean;
	defaultIndex?: number;
}) {
	const [currentSlide, setCurrentSlide] = useState(defaultIndex ?? 0);
	const handlers = useSwipeable({
		onSwipedLeft: () => {
			if (useSwipe) {
				nextSlide();
			}
		},
		onSwipedRight: () => {
			if (useSwipe) {
				previousSlide();
			}
		},
		preventScrollOnSwipe: true,
		trackMouse: true,
		trackTouch: true,
		touchEventOptions: { passive: true },
	});
	const params = useParams();

	useEffect(() => {
		updateCurrentItemWithHash(window.location.hash);
		updateCurrentSlide(defaultIndex ?? 0);
	}, []);

	useEffect(() => {
		updateCurrentItemWithHash(window.location.hash);
	}, [params]);

	const getItemIndexWithHash = (hash: string) => items.findIndex((item) => item.href === hash);

	const updateCurrentItemWithHash = (hash: string) => {
		const currentItem = getItemIndexWithHash(hash);

		if (currentItem !== -1) {
			updateCurrentSlide(currentItem);
			dispatchAriaLiveContent(
				`Élément ${(currentItem + 1).toString(10)} sur ${items.length.toString(10)}`,
			);
		}
	};

	const goToSlide = (slideIndex: number) => {
		removeHash();
		updateCurrentSlide(slideIndex);
		dispatchAriaLiveContent(
			`Élément ${(slideIndex + 1).toString(10)} sur ${items.length.toString(10)}`,
		);
	};

	const nextSlide = () => {
		goToSlide(currentSlide + 1 > items.length - 1 ? items.length - 1 : currentSlide + 1);
	};

	const previousSlide = () => {
		goToSlide(currentSlide - 1 < 0 ? 0 : currentSlide - 1);
	};

	const updateCurrentSlide = (slideIndex: number) => {
		setCurrentSlide(slideIndex);

		if (setCurrentSlideProps) {
			setCurrentSlideProps(slideIndex);
		}
	};

	const getLeftPosition = (slideIndex: number) => {
		const position = (slideIndex - currentSlide) * 100;

		if (position > 0) {
			return position + 10;
		}

		if (position < 0) {
			return position - 10;
		}

		return 0;
	};

	const transitionClasses = 'transition-all duration-500 ease-in-out';

	return (
		<div className="grid">
			<ul
				{...handlers}
				className={cn('relative row-start-1 w-full overflow-hidden lg:row-start-2', carouselClass)}
			>
				{items.map((child, index) => (
					<li
						key={`carousel-item-${index.toString(10)}`}
						aria-hidden={currentSlide === index ? 'false' : 'true'}
						style={{
							left: `${getLeftPosition(index).toString(10)}%`,
						}}
						className={cn(
							'absolute bottom-0 left-0 top-0 w-full px-5',
							transitionClasses,
							itemClass,
						)}
					>
						{child.content}
					</li>
				))}
			</ul>

			{!items.every((child) => !child) && (
				<ul className="hidden lg:row-start-1 lg:m-auto lg:mb-10 lg:flex lg:w-fit lg:items-center lg:gap-[0.375rem] lg:rounded-[3.125rem] lg:border-[0.0625rem] lg:border-grey-light lg:bg-white lg:p-[0.625rem]">
					{items.map((child, index) => (
						<li key={`carousel-item-nav-${index.toString(10)}`} id={child.href?.split('#')[1]}>
							<button
								type="button"
								onClick={() => {
									goToSlide(index);
								}}
							>
								{child.title}
							</button>
						</li>
					))}
				</ul>
			)}

			<ul className="row-start-2 flex justify-center gap-[0.375rem] lg:row-start-3 lg:mt-[1.5625rem]">
				{items.map((child, index) => (
					<li
						key={`carousel-item-nav-${index.toString(10)}`}
						id={child.href?.split('#')[1]}
						className="z-10"
					>
						<button
							type="button"
							onClick={() => {
								goToSlide(index);
							}}
							className={cn('h-[0.625rem] rounded-[20px]', transitionClasses, {
								'w-[0.625rem] bg-grey-light': currentSlide !== index,
								'w-[1.25rem] bg-dodger-blue-500': currentSlide === index,
							})}
							aria-label={`Aller à l'élément ${(index + 1).toString(10)} sur ${items.length.toString(10)}`}
						>
							<span className="invisible absolute row-start-3 -m-[0.0625rem] h-[0.0625rem] w-[0.0625rem] overflow-hidden border-0 p-0">
								{child.title}
							</span>
						</button>
					</li>
				))}
			</ul>
		</div>
	);
}

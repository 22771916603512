import LinkButton from '@/components/commons/link-button';
import ArrowCircleRightIcon from '@/components/icons/arrow-circle-right';
import CheckCircleIcon from '@/components/icons/check-circle';
import { cn } from '@/lib/utils';
import { PricingCarouselItem } from '@/models/home-pricing-item';
import Image from 'next/image';
import { memo } from 'react';
import CountUp from 'react-countup';

function Card({
	item,
	billing,
	isLast,
}: {
	item: PricingCarouselItem;
	billing: 'yearly' | 'monthly';
	isLast: boolean;
}) {
	return (
		<div
			className={cn(
				'relative grid h-full grid-rows-[minmax(0,min-content)_minmax(0,min-content)_minmax(0,min-content)_minmax(0,1fr)] rounded-3xl border border-grey-light bg-white p-5',
				{
					'bg-gradient-to-b from-[#3C3859] to-[#19162F] text-white': item.darkMode,
					"after:absolute after:-right-[30px] after:-top-[40px] after:hidden after:h-14 after:w-14 after:bg-[url('/pricing/right-top.svg')] after:content-['']":
						isLast,
				},
			)}
		>
			<div className="row-start-2">
				<h3 className="row-start-2 text-xl font-bold leading-normal">{item.title}</h3>

				{(item.priceText || item.price) && (
					<h4 className="row-start-2 flex items-center font-lora text-[2.125rem] font-bold leading-normal">
						{item.price ? (
							<>
								{billing === 'yearly' ? (
									<CountUp
										end={item.price.yearly}
										duration={1}
										separator=" "
										decimals={2}
										decimal=","
										suffix="€"
									/>
								) : billing === 'monthly' ? (
									<CountUp
										end={item.price.monthly}
										duration={1}
										separator=" "
										decimals={2}
										decimal=","
										suffix="€"
									/>
								) : null}
								<span className="ml-2 font-poppins text-base font-medium text-grey-darker">
									{billing === 'yearly' ? 'par an' : 'par mois'}
								</span>
							</>
						) : (
							item.priceText
						)}
					</h4>
				)}
			</div>

			<div className="row-start-1 mb-[0.9375rem] flex items-center gap-3">
				<Image src={item.illustration} alt="" width={42} height={42} />
				<p className="text-lg font-semibold leading-normal">{item.pricingName}</p>
			</div>

			<div>
				<div
					className={cn('mb-6 mt-8 h-px', {
						'bg-[#EDEFFF]': !item.darkMode,
						'bg-[#26223E]': item.darkMode,
					})}
				></div>
				<h5 className="mb-2 text-base font-semibold">Fonctionnalités&nbsp;:</h5>
				<ul className="grid gap-4">
					{item.features.map((feature) => (
						<li
							key={feature as string}
							className="grid grid-cols-[minmax(0,1.5rem)_minmax(0,1fr)] items-center gap-2 text-base font-medium"
						>
							<CheckCircleIcon
								width={20}
								height={20}
								className={cn('self-start', {
									'text-green-500': item.featuresColor === 'green',
									'text-grey-dark': item.featuresColor === 'grey',
								})}
							/>
							<p
								dangerouslySetInnerHTML={{
									__html: feature,
								}}
							/>
						</li>
					))}
				</ul>
			</div>

			<div className="row-start-3 mt-8 self-end">
				{item.link ? (
					<LinkButton href={item.link}>
						{item.buttonText}
						<ArrowCircleRightIcon width={24} height={24} />
					</LinkButton>
				) : (
					<LinkButton href={`/sign-up?plan=${item.stripePlan}`}>
						{item.buttonText}
						<ArrowCircleRightIcon width={24} height={24} />
					</LinkButton>
				)}

				{item.footerText ? (
					<p className="mt-3 text-center text-base font-medium text-grey-dark underline underline-offset-2">
						{item.footerText}
					</p>
				) : (
					<div className="mt-3 h-6 text-center text-base font-medium text-grey-dark underline underline-offset-2">
						{' '}
					</div>
				)}
			</div>
		</div>
	);
}

export default memo(Card);

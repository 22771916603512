export default function ArrowCircleRightIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
			<path
				stroke="currentcolor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="m12 16 4-4m0 0-4-4m4 4H8m14 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
			/>
		</svg>
	);
}

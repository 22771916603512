import { EmojiName } from '@/models/emoji-name';
import Image from 'next/image';
import { memo } from 'react';

function Emoji({
	name,
	width = 32,
	height = 32,
	className,
	priority = false,
}: {
	name: EmojiName;
	width?: number;
	height?: number;
	className?: string;
	priority?: boolean;
}) {
	const isAnimated = name.startsWith('animated');
	return (
		<Image
			src={`/emojis/${name}.png`}
			alt=""
			role="presentation"
			width={width}
			height={height}
			className={className}
			priority={priority}
			unoptimized={isAnimated}
		/>
	);
}

export default memo(Emoji);

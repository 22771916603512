'use client';

import { HomeFooterItem, ItemFooter, ItemFooterAction, ItemFooterLink } from '@/models/home-footer';
import { clsx } from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { memo } from 'react';

function FooterItem({ item }: { item: HomeFooterItem }) {
	const isLink = (item: ItemFooter): item is ItemFooterLink => item.type === 'link';
	const isAction = (item: ItemFooter): item is ItemFooterAction => item.type === 'action';

	return (
		<li
			className={clsx({
				'text-white': !!item.title,
				'row-start-4 lg:row-start-1': !!item.picture,
			})}
		>
			{item.picture && !item.picture.link && (
				<Image
					src={item.picture.src}
					width={item.picture.width}
					alt=""
					role="presentation"
					title={item.picture.title}
				/>
			)}
			{item.picture && item.picture.link && (
				<Link
					href={item.picture.link.href}
					className="inline-block"
					aria-label={item.picture.ariaLabel}
				>
					<Image
						src={item.picture.src}
						width={item.picture.width}
						alt=""
						role="presentation"
						title={item.picture.title}
					/>
				</Link>
			)}
			{item.title && <span className="mb-[0.625rem] inline-block">{item.title}</span>}
			{item.links && (
				<ul className="grid gap-2 text-grey-dark">
					{item.links.map((subItem) => (
						<li key={subItem.id}>
							{isLink(subItem) && (
								<Link href={subItem.href} className="hover:underline hover:underline-offset-2">
									{subItem.text}
								</Link>
							)}
							{isAction(subItem) && (
								<button
									onClick={() => {
										subItem.fn();
									}}
									type="button"
									className="hover:underline hover:underline-offset-2"
								>
									{subItem.text}
								</button>
							)}
						</li>
					))}
				</ul>
			)}
		</li>
	);
}

export default memo(FooterItem);

export default function Menu03(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
			<path
				stroke="currentcolor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M3 12h18M3 6h18M3 18h12"
			/>
		</svg>
	);
}

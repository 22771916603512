import { HomeFooterItem } from '@/models/home-footer';
import LogoWhite from '@/public/logos/logo-white.svg';

export const HomeFooterItems: HomeFooterItem[] = [
	{
		id: 'logo',
		picture: {
			src: LogoWhite,
			width: 123,
			link: { href: '/', id: 'logo-link', type: 'link' },
			ariaLabel: "Lien pour retourner à la page d'accueil",
			title: 'Logo de la plateforme Lokappy',
		},
	},
	{
		id: 'features',
		title: 'Fonctionnalités',
		links: [
			{
				id: 'link-tenant-handle',
				text: 'Gestion locative',
				href: '/features#tenants-messages',
				type: 'link',
			},
			{
				id: 'link-contract',
				text: 'États des lieux',
				href: '/features#contracts',
				type: 'link',
			},
			{
				id: 'link-documents',
				text: 'Documents',
				href: '/features#documents',
				type: 'link',
			},
		],
	},
	{
		id: 'about',
		title: 'À propos',
		links: [
			{
				id: 'link-contact',
				text: 'Contact',
				href: 'mailto:contact@lokappy.co',
				type: 'link',
			},
			{
				id: 'link-price',
				text: 'Tarifs des formules',
				href: '/price',
				type: 'link',
			},
			{
				id: 'link-blog',
				text: 'Blog',
				href: '/blog',
				type: 'link',
			},
		],
	},
	{
		id: 'legal-mentions',
		title: 'Mentions Légales',
		links: [
			{
				id: 'link-cgu',
				text: "Conditions Générales d'Utilisation",
				href: '/legal/cgu',
				type: 'link',
			},
			{
				id: 'link-cgv',
				text: 'Conditions Générales de Ventes',
				href: '/legal/cgv',
				type: 'link',
			},
			{
				id: 'link-privacy-policy',
				text: 'Politique de confidentialité',
				href: '/legal/privacy-policy',
				type: 'link',
			},
			{
				id: 'cookies',
				text: 'Gestion des cookies',
				type: 'action',
				fn: () => {
					(window as any).__axeptioSDK.openCookies();
				},
			},
		],
	},
];

'use client';

import NavDesktop from '@/components/home/nav/nav-desktop';
import NavMobile from '@/components/home/nav/nav-mobile';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { memo, useEffect, useState } from 'react';

function Nav() {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		document.addEventListener('keyup', exitNavOnEscape);

		return () => {
			document.removeEventListener('keyup', exitNavOnEscape);
			clearAllBodyScrollLocks();
		};
	}, []);

	const exitNavOnEscape = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			setIsOpen(false);
		}
	};

	const changeIsOpen = (isOpen: boolean) => {
		setTimeout(() => {
			setIsOpen(isOpen);

			if (isOpen) {
				disableBodyScroll(document.body);
			} else {
				enableBodyScroll(document.body);
			}
		}, 200);
	};

	return (
		<>
			<NavDesktop isOpen={isOpen} setIsOpen={changeIsOpen} />
			<NavMobile isOpen={isOpen} setIsOpen={changeIsOpen} />
		</>
	);
}

export default memo(Nav);

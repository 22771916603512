import Emoji from '@/components/commons/emoji';
import LinkButton from '@/components/commons/link-button';
import ArrowCircleRightIcon from '@/components/icons/arrow-circle-right';
import CheckCircleIcon from '@/components/icons/check-circle';
import { HomeFeaturesCarouselItemContent } from '@/models/home-carousel-item';
import Image from 'next/image';
import Link from 'next/link';
import { memo } from 'react';

function CarouselItem({ content }: { content: HomeFeaturesCarouselItemContent }) {
	return (
		<div className="grid h-full grid-rows-[minmax(0,0.43fr)_minmax(0,0.57fr)] overflow-hidden rounded-[30px] bg-white shadow-[0_0_20px_0_rgba(115,108,165,0.10)] lg:grid-cols-[minmax(0,0.399fr)_minmax(0,0.601fr)] lg:grid-rows-1 lg:gap-8">
			<div className="row-start-2 px-[0.9375rem] py-[1.0625rem] lg:row-start-1 lg:pb-10 lg:pl-10 lg:pt-[3.125rem]">
				<h3 className="mb-5 whitespace-pre text-[1.375rem] font-bold leading-normal lg:text-[2rem] lg:leading-[1.3125]">
					{content.title}
				</h3>
				<p className="hidden lg:mb-[1.875rem] lg:block lg:text-base lg:font-normal lg:leading-6">
					{content.subtitle}
				</p>

				{content.features && (
					<ul className="grid gap-[1.125rem]">
						{content.features.map((feature) => (
							<li
								className="grid grid-cols-[minmax(0,1.5rem)_minmax(0,1fr)] items-center gap-2 text-base font-medium leading-6"
								key={feature}
							>
								<CheckCircleIcon width={24} height={24} className="text-green-500" />
								{feature}
							</li>
						))}
					</ul>
				)}

				{content.sentence && (
					<div className="flex w-fit items-center gap-[0.625rem] rounded-xl bg-dodger-blue-500 bg-opacity-10 px-4 py-3">
						<Emoji name={content.sentence.emoji} width={32} height={32} />
						<p className="text-sm font-semibold leading-normal lg:whitespace-pre">
							{content.sentence.content + '\n'}
							<Link
								href={content.sentence.link.url}
								target="_blank"
								rel="noopener noreferrer"
								className="text-dodger-blue-500 underline underline-offset-2"
							>
								{content.sentence.link.content}
							</Link>
						</p>
					</div>
				)}

				<LinkButton href="/sign-up" className="absolute bottom-10 hidden w-fit lg:flex">
					Gérer mes locations
					<ArrowCircleRightIcon width={24} height={24} />
				</LinkButton>
			</div>

			<div className="relative row-start-1 overflow-hidden bg-dodger-blue-500 pt-5 after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[3.625rem] after:bg-gradient-to-b after:from-transparent after:to-dodger-blue-500 after:content-[''] lg:grid lg:grid-cols-2 lg:items-center lg:gap-9 lg:pt-0">
				<Image
					src={content.pictures.feature}
					sizes="(max-width: 767px) 240px, 335px"
					className="m-auto w-[15rem] lg:m-0 lg:w-[20.9375rem] lg:justify-self-end"
					alt=""
					role="presentation"
				/>
				<Image
					src={content.pictures.phone}
					width={300}
					className="mt-auto hidden justify-self-start lg:block"
					alt=""
					role="presentation"
				/>
			</div>
		</div>
	);
}

export default memo(CarouselItem);

import Emoji from '@/components/commons/emoji';
import { EmojiName } from '@/models/emoji-name';
import { clsx } from 'clsx';
import React, { memo } from 'react';

function CarouselTitle({
	slideIndex,
	currentSlide,
	emoji,
	children,
}: {
	slideIndex: number;
	currentSlide: number;
	emoji: EmojiName;
	children: React.ReactNode;
}) {
	return (
		<span
			className={clsx(
				'flex items-center gap-2 rounded-[2.5rem] px-3.5 py-[0.5625rem] font-medium leading-6 transition-all duration-500 ease-in-out',
				{
					'bg-dodger-blue-500 text-white': currentSlide === slideIndex,
					'bg-transparent text-black-text hover:bg-dodger-blue-500 hover:bg-opacity-10':
						currentSlide !== slideIndex,
				},
			)}
		>
			<Emoji
				name={emoji}
				width={32}
				height={32}
				className={clsx({
					'opacity-50': currentSlide !== slideIndex,
				})}
			/>
			{children}
		</span>
	);
}

export default memo(CarouselTitle);

'use client';

import FaqListItem from '@/components/faq/list-item';
import type { Faq } from '@/models/faq';
import { memo } from 'react';

function FaqList({ faq }: { faq: Faq[] }) {
	return (
		<dl className="mt-5">
			{faq.map((faqItem) => (
				<FaqListItem key={faqItem.id} faq={faqItem} />
			))}
		</dl>
	);
}

export default memo(FaqList);

import { PricingCarouselItem } from '@/models/home-pricing-item';
import { StripePlanEnum } from '@/models/stripe-plan.enum';
import StatSvg from '@/public/pricing/illustrations/stat.svg';
import TargetSvg from '@/public/pricing/illustrations/target.svg';
import ThumbSvg from '@/public/pricing/illustrations/thumb.svg';

export const HomePricingCarousel: PricingCarouselItem[] = [
	{
		id: 'pricing-free',
		illustration: ThumbSvg,
		featuresColor: 'grey',
		pricingName: 'Gratuit',
		price: {
			monthly: 0,
			yearly: 0,
		},
		title: 'Découvrir Lokappy',
		footerText: 'Aucune CB demandée',
		features: ["Jusqu'à <strong>1 lot</strong>", 'Accès aux fonctionnalités de base'],
		stripePlan: StripePlanEnum.free,
		buttonText: 'Essayer gratuitement',
	},
	{
		id: 'pricing-premium',
		illustration: TargetSvg,
		featuresColor: 'green',
		pricingName: 'Premium',
		title: 'Dédié au propriétaires bailleurs',
		price: {
			monthly: 9.99,
			yearly: 99.99,
		},
		footerText: 'Déductible des impôts',
		features: [
			'Lots <strong>illimités</strong>',
			'Locataires <strong>illimités</strong>',
			'Gestion de travaux avec <strong>IA</strong>',
			'Gestion & stockage de <strong>documents</strong>',
			'Gestion des candidatures grâce à <strong>Dossier facile</strong>',
		],
		stripePlan: StripePlanEnum.premium,
		buttonText: 'Souscrire',
	},
	{
		id: 'pricing-premium-custom',
		illustration: StatSvg,
		featuresColor: 'green',
		pricingName: 'Professionnel',
		title: 'Professionnel, SCI, etc...',
		priceText: 'Nous contacter',
		features: [
			'Lots <strong>illimités</strong>',
			'Locations <strong>illimités</strong>',
			'Support <strong>prioritaire</strong>',
			'Accès illimité aux fonctionnalités <strong>premium</strong>',
		],
		stripePlan: StripePlanEnum['premium-custom'],
		buttonText: 'Nous contacter',
		darkMode: true,
		link: 'mailto:contact@lokappy.co',
	},
];

'use client';

import Carousel from '@/components/commons/carousel';
import Toggle from '@/components/commons/toggle';
import Card from '@/components/home/pricing/card';
import { HomePricingCarousel } from '@/contents/home-pricing-carousel';
import { cn } from '@/lib/utils';
import { StripePlanEnum } from '@/models/stripe-plan.enum';
import FiscaliteSvg from '@/public/pricing/fiscalite.svg';
import { ArrowCircleRight } from '@untitled-ui/icons-react';
import Image from 'next/image';
import Link from 'next/link';
import { memo } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
	billing: boolean;
};

function PricingPageContent() {
	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useForm<FormData>({ defaultValues: { billing: false } });

	const billingValue = watch('billing');

	return (
		<>
			<div className="mb-7 flex items-center justify-center gap-3.5">
				<button
					type="button"
					onClick={() => {
						setValue('billing', !billingValue);
					}}
					className={cn('text-lg leading-normal transition-all', {
						'font-semibold': !billingValue,
						'font-normal': billingValue,
					})}
				>
					Mensuel
				</button>
				<Toggle
					registered={register('billing')}
					error={errors.billing}
					alwaysPrimary={true}
					label="Changement de récurrence de paiement"
					hideLabel={true}
				/>
				<button
					type="button"
					onClick={() => {
						setValue('billing', !billingValue);
					}}
					className={cn('flex items-center gap-3.5 text-lg leading-normal transition-all', {
						'font-semibold': billingValue,
						'font-normal': !billingValue,
					})}
				>
					Annuel
				</button>
			</div>

			<div className="relative hidden lg:block">
				<ul className="mx-auto mt-[1.75rem] grid max-w-[72.5rem] grid-cols-3 gap-8">
					{HomePricingCarousel.map((item, i, { length }) => {
						const isLast = i + 1 === length;
						return (
							<li key={item.id}>
								<Card item={item} billing={billingValue ? 'yearly' : 'monthly'} isLast={isLast} />
							</li>
						);
					})}
				</ul>
			</div>

			<div className="-mx-5 -my-5 block lg:hidden">
				<Carousel
					items={HomePricingCarousel.map((item, i, { length }) => {
						const isLast = i + 1 === length;
						return {
							content: (
								<Card
									key={item.id}
									item={item}
									billing={billingValue ? 'yearly' : 'monthly'}
									isLast={isLast}
								/>
							),
						};
					})}
					carouselClass="h-[43rem]"
					itemClass="py-10"
					defaultIndex={1}
				/>
			</div>

			<section className="mx-auto mt-8 flex min-h-[246px] max-w-[72.5rem] flex-col-reverse gap-6 rounded-3xl bg-[url('/pricing/fiscalite.png')] bg-cover p-8 lg:flex-row">
				<div className="text-center lg:text-left">
					<h2 className="mb-4 font-lora text-[1.75rem] font-bold leading-[1.5]">
						Profitez d&apos;un avantage fiscal&nbsp;!
					</h2>

					<p className="text-base font-normal">
						En souscrivant à Lokappy, vous simplifiez la gestion de vos biens tout en déduisant vous
						frais de gestion locative de vos revenus locatifs (20&nbsp;€ par an et par logement).
						Transformez votre abonnement en un investissement optimisé&nbsp;!
					</p>

					<div className="mt-6 flex flex-col items-center gap-6 lg:flex-row">
						<Link
							href={`/sign-up?plan=${StripePlanEnum.premium}`}
							className="flex w-fit items-center gap-3 rounded-xl border border-grey-light bg-white px-4 py-3 font-medium transition-colors hover:bg-grey-light"
						>
							Souscrire pour 49.99&nbsp;€/an
							<ArrowCircleRight width={20} height={20} />
						</Link>

						<Link
							href="https://bofip.impots.gouv.fr/bofip/5804-PGP.html/identifiant=BOI-RFPI-BASE-20-10-20160706"
							target="_blank"
							rel="noopener noreferrer"
							className="font-medium underline underline-offset-2"
						>
							En savoir plus
						</Link>
					</div>
				</div>

				<div className="shrink-0 grow-0 basis-[189px]">
					<Image src={FiscaliteSvg} width={189} height={182} alt="" className="mx-auto" />
				</div>
			</section>
		</>
	);
}

export default memo(PricingPageContent);
